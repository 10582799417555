import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Compose from 'reactjs-compose';
import reportWebVitals from './reportWebVitals';
import { store } from './store/Store';
import CenterProvider from './context';
import App from './App';
import './data';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <Compose
        components={[CenterProvider]}
      >
        <App />
      </Compose>,
    </BrowserRouter>
  </Provider>,

  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
